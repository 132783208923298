import React from "react";
import {connect} from "react-redux";
import {SET_SELECTED_SEARCH} from "../../redux/tabs";
import Icon from "../Icon";
import {defaultTheme} from "../../theme";
import {IconButton, Tooltip} from "@mui/material";
import { Link } from 'react-router-dom';


const Search = ({setSearch}) => {

    const addSearch = () => {
        let id = Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1)
        setSearch({
            id: id
        })
    }
    return (
        <Link to="/" >
            <Tooltip title={'Search'} placement={'right'} arrow={true}>
                <IconButton onClick={addSearch} style={{padding: 0, marginLeft: '5px'}}>
                    <Icon name={'Search'} style={{height: '1.8rem', width: '1.8rem', color: defaultTheme.searchColor}}/>
                </IconButton>
            </Tooltip>
        </Link>
    )
}

export default connect(
    ({tabs}) => ({tabs}),

    (dispatch) => ({
        setSearch: (payload) => dispatch(SET_SELECTED_SEARCH(payload)),
    })
)(Search);
