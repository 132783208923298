import {FormattedDate, FormattedTime} from "react-intl";
import useAuth from "../../hooks/useAuth";
import useFormatDate from "../../hooks/useFormatDate";

export default ({date, specific=true}) => {
    const formattedDate = useFormatDate(date);
    //console.log("datexxx", date)
    //console.log("formattedDate", formattedDate)

    if (date == null) return <>-</>

    const getTime = () => {
        if (specific) {
            return (
                <>
                    ,&nbsp;
                    <FormattedTime value={formattedDate}/>

                </>
            )
        }
    }

    return (
        <>
            <FormattedDate
                value={formattedDate}
            />{getTime()}
        </>
    )
}