import Axios from 'axios';
import {API, LOGIN} from '../config';
import createAuthRefreshInterceptor from "axios-auth-refresh";
import authAPI from "./authAPI";

export const apis = {
    GET_REPERTORIES: '/documents',
    GET_CHAPTERS: '/chapter/search',
    GET_SYMPTOMS: '/rubric/search',
    GET_PARENTS: '/rubric/parents',
    SLIDE_SYMPTOMS: '/rubric/slide',
    LIST_TRANSLATION_TYPES: '/lookup/list/translation-types',
    LIST_TRANSLATIONS: '/translations/list',
    LIST_TRANSLATION_VERSIONS: '/translations/list-versions',
    GET_UNTRASLATED_RUBRIC: '/translations/untraslated-rubric',
    GET_TAGS: '/tag/list',
    GET_NOTE: '/note/get',
    GET_REFERENCES: '/GetReferences',
    GET_PATIENTS: '/GetPatients',
    GET_PATIENT: '/GetPatient',
    CREATE_CLIPBOARD: '/clipboard/create',
    SAVE_NOTE: '/note/save',
    SAVE_TRANSLATIONS: '/translation/update',
    ADD_CLIPBOARD_RUBRIC: '/clipboard/add-rubric'
};


export const getDefParams = () =>  {
   return {lang: localStorage.getItem('i18nextLng')}
};

export const serviceInterface = Axios.create({
    baseURL: `${API.url}${API.path}`
});

serviceInterface.interceptors.request.use((request) => {
        request.withCredentials = true;
        //https://stackoverflow.com/questions/77289135/removed-cookie-from-request-adding-custom-headers
        //request.headers['x-zeus-app-id'] = 'CC';
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/* Moved to <Notifications />
serviceInterface.interceptors.response.use((response) => {
    return {...response?.data};
}, (error) => {
    //console.log("error", error)
    return Promise.reject(error);
});
*/

const refreshAuthLogic = async () => {
    try {
        await authAPI.refreshToken()
    } catch (e) {
        window.location.href = `${LOGIN.url}`
    }
}

createAuthRefreshInterceptor(serviceInterface, refreshAuthLogic);
