import {IconButton, Tooltip} from "@mui/material";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import {defaultTheme} from "../../theme";
import React from "react";
import useAuth from "../../hooks/useAuth";
import useConstants from "../../hooks/useConstants";

export default ({onClick}) => {
    const {roles} = useAuth();
    const {getApp} = useConstants();

    const app = getApp();

    const showStatistics = true //( roles?.['TRA'] || roles?.['ADM'] );

    return (
        <>
            {showStatistics &&
            <Tooltip title={'Statistics'} placement={'right'} arrow={true}>
                <IconButton onClick={onClick}>
                    <LeaderboardIcon style={{color: defaultTheme.translationColor}}/>
                </IconButton>
            </Tooltip>
            }
        </>
    )
}
