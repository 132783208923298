import { useSnackbar } from "notistack";
import { serviceInterface } from './../../services/config';

const Notifications = () => {
	const { enqueueSnackbar } = useSnackbar()
	serviceInterface.interceptors.response.use((response) => {
		return {...response?.data}
	}, (error) => {
		enqueueSnackbar('Error', {
			variant: 'error'
		})    
	})
	return <></>
}

export default Notifications